<template>
  <div class="main" :class="show ? 'h5main' : ''">
  	<div class="htop">
		<mtop />
		</div>
		<div class="banner">
				<img
            src="../assets/ximg/banner-xwzx@2x.png"
            alt="szzc"
            class="simg"
          />
		</div>
    <div class="main_box">
      <!-- <div class="slideshow">
        <van-swipe
          class="my-swipe"
          :options="swiperOption"
          :show-indicators="false"
          ref="news"
        >
          <van-swipe-item v-for="(item,i) in list" :key="i" >
            <div class="item" >
              <img :src="item.thumb" alt="" srcset="" />
              <div class="title">{{item.title}}</div>
            </div>
          </van-swipe-item>         
        </van-swipe>
        <div class="pre" @click="pre"></div>
        <div class="next" @click="next"></div>
      </div> -->
      <div class="box_top">
        <div
          class="left_item"
          :class="firstIndex == item.id ? 'show' : ''"
          @click="clickNav(item.id, index)"
          v-for="(item, index) in navtitle"
          :key="index"
        >
          <div class="l_title">{{ item.name }}</div>
        </div>
      </div>
      <div class="box_right" v-if="list.length > 0">
        <div class="right_item" v-for="(item, index) in list" :key="index">
          <div class="item_img">
            <img :src="item.thumb" alt="" />
          </div>
          <div class="item_con">
            <div class="con1">{{ item.title }}</div>
            <div class="con2">{{ item.description }}</div>
            <div class="con3">
              <div class="con3_tab" v-for="(items, i) in item.label" :key="i">
                {{ items }}
              </div>
            </div>
            <div class="con4">
              发布时间：{{ item.create_time }} 作者：{{
                item.author
              }}
              浏览量：{{ item.click }}
            </div>
            <div class="btn" @click="toPage(item)">查看详情</div>
          </div>
        </div>

        <el-pagination
          v-show="listQuery.total > 1"
          :page-size.sync="listQuery.page_size"
          :current-page.sync="listQuery.page"
          @pagination="getList"
          :background="!show"
          class="page"
          layout="prev, pager, next"
          @current-change="handleCurrentChange"
          :total="listQuery.total"
        >
        </el-pagination>
      </div>
      <div class="box_right" v-else>
        <van-empty description="暂无更过信息~" />
      </div>
    </div>

    <mbottom />

    <!-- 提交成功的弹窗 -->
    <!-- <van-popup v-model="showlist" round :close-on-click-overlay="false">
         <div :class="show==false?'popup':'popuph5'">
            <div class="img">
               <img src="../assets/image/correct.png" alt="">
            </div>
            <div class="con">信息提交成功，我们正快马加鞭的处理中~</div>
            <div class="bottom">
               <div @click="backBtn">返回</div>
            </div>
         </div>
      </van-popup> -->
  </div>
</template>

<script>
import { connectTron } from "@/api/Public";
import mtop from "../components/top.vue";
import mbottom from "../components/bottom.vue";
export default {
  data() {
    return {
      listQuery: {
        page: 1,
        page_size: 5,
        total: 0,
        type: 2, //新闻
      },
      opacityval: 0,
      backgroundurl:
        "https://www.xingrunkeji.cn//storage/uploads/20220228/b2b35a5f2f920c6a2b3f919da64317d4.png",
      ploblem: {
        id: 1,
        name: "常见问题",
        english: "problem",
        back_img:
          "https://www.xingrunkeji.cn//storage/uploads/20220228/b2b35a5f2f920c6a2b3f919da64317d4.png",
        status: 2,
        sort: 4,
        update_time: "2022-02-25 15:52:18",
        create_time: "2021-08-13 17:55:32",
      },
      navtitle: [],
      firstIndex: 1,
      show: false,
      showlist: false, //提交成功的弹窗
      form: {
        name: "",
        region: "",
        date1: "",
        date2: "",
        delivery: false,
        type: [],
        resource: "",
        desc: "",
      },
      list: [],
      swiperOption: {
        //循环  实现无限切换
        loop: true,
        navigation: {
          //下一张
          nextEl: ".next", //下一张标签类名可以自定义
          //上一张
          prevEl: ".pre", //上一张标签类名可以自定义
        },
      },
    };
  },

  components: {
    mtop,
    mbottom,
  },

  mounted() {
    if (this._isMobile()) {
      this.show = true;
    } else {
      this.show = this.$store.getters.phone;
    }

    this.getInfo();
  },
  watch: {
    "$store.getters.conid": function (val) {
      sessionStorage.setItem("conid", val);
    },
    "$store.getters.phone": function (val) {
      this.show = val;
    },
    deep: true,
    immediate: true,
  },
  created() {
    this.getList();
  },
  methods: {
    next(e) {
      this.$refs.news.next(e);
    },
    pre(e) {
      this.$refs.news.prev(e);
    },
    handleCurrentChange(val) {
      this.listQuery.page = val;
      this.getList();
    },
    getInfo() {
      this.$axios({
        method: "get",
        url: "/v1/cateList",
      })
        .then((res) => {
          this.navtitle = res.data.data.list;
          this.navtitle.unshift(this.ploblem);
        })
        .catch(() => {
          console.log("请求失败");
        });
    },
    clickNav(e) {
      if (this.firstIndex == e) {
        return false;
      } else {
        this.firstIndex = e;
        this.listQuery.cate_id = e;
        this.getList();
      }
    },
    toPage(row) {
      sessionStorage.setItem("conid", row.id);

      this.$router.push({
        name: "teamdetal",
        params: {
          detail: row,
        },
      });
    },
    backBtn() {
      //点击返回取消弹框
      this.showlist = false;
    },
    wallet() {
      var i = 0;
      var a = setInterval(() => {
        if (typeof window.ethereum !== "undefined") {
          connectTron().then(
            (info) => {
              clearInterval(a);
              if (info[0].indexOf("0x") == 0) {
                if (info[0] != this.$store.getters.userWallet) {
                  this.buy = true;
                }
                this.$store.commit("userWallet", info[0]);
              } else {
                this.$toast("Use the ETH address to log in to the game");
              }
            },
            (e) => {
              this.$toast(e.message);
            }
          );
        }
        if (i > 5) {
          clearInterval(a);
        }
        i++;
      }, 1000);
    },
    _isMobile() {
      let flag = navigator.userAgent.match(
        /(phone|pod|iPhone|iPod|ios|Android|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      );
      return flag;
    },
    topage(e) {
      this.$router.replace({ path: e });
      this.$store.commit("sid", "");
    },
    tobuy() {
      location.href = "https://opensea.io/collection/cupid-love-metaverse";
    },
    up() {
      if (this.sqloading) {
        return;
      }
      this.buynum = Number(this.buynum) + 1;
    },
    dow() {
      if (this.sqloading) {
        return;
      }
      this.buynum = this.buynum <= 1 ? 1 : Number(this.buynum) - 1;
    },
    close() {
      this.shows = false;
    },
    handleFiexdToolItem(e) {
      if (!e) {
        return;
      }
      this.$nextTick(() => {
        document.getElementById(e).scrollIntoView({
          behavior: "smooth",
          block: "start",
          inline: "nearest",
        });
      });
    },

    getList() {
      this.$axios({
        method: "get",
        url: "/v1/articleList",
        params: this.listQuery,
      })
        .then((res) => {
          this.list = [];
          this.list = res.data.data.list.data;
          //  this.listQuery.total=res.data.list.total;
          this.listQuery.total = res.data.data.list.total;
        })
        .catch(() => {});
    },
  },
};
</script>
<style lang="scss" scoped>
$t-mf: "Arial";
.main {
  background: #f7f7f7;
  max-width: 100%;
  min-width: 1360px;
  width: 100%;
  .htop{
		    height: 70px;
	    }	
        .banner{
    width: 100%;
    font-size: 0;
    .simg{
        width: 100%;
        height: auto;
    }
  }
  .box1_top {
    width: 1200px;
    margin: 0 auto;
    display: flex;
    text-align: left;
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #999999;
    line-height: 50px;

    align-items: center;
    .img {
      width: 24px;
      height: 24px;
      background: url("../assets/indeximg/point.png");
      background-size: 100% 100%;
      margin-right: 12px;
    }
    div > span {
      color: #0052da;
      cursor: pointer;
    }
  }
  .main_box {
    width: 1200px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    margin-bottom: 40px;
    flex-direction: column;
    .slideshow {
      width: 100%;
      height: 400px;
      margin: 24px 0 0;
      position: relative;
      .pre {
        background: url("../assets/ximg/icon-zuo.png") no-repeat;
        background-size: 100% 100%;
        width: 32px;
        height: 32px;
        position: absolute;
        left: 16px;
        top: 50%;
        transform: translateY(-50%);
        z-index: 100;
      }
      .next {
        background: url("../assets/ximg/icon-you.png") no-repeat;
        background-size: 100% 100%;
        width: 32px;
        height: 32px;
        position: absolute;
        left: calc(100% - 46px);
        top: 50%;
        transform: translateY(-50%);
        z-index: 100;
      }
      .my-swipe {
        width: 1200px;
        height: 400px;
        position: relative;
      }
      .item {
        position: relative;
        width: 1200px;
        height: 400px;
        font-size: 0;
        img {
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
        }
        .title {
          background: rgba(0, 0, 0, 0.6);
          position: absolute;
          width: 100%;
          bottom: 0;
          left: 0;
          height: 81px;
          line-height: 81px;
          font-size: 24px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #ffffff;
          padding-left: 33px;
          text-align: left;
          box-sizing: border-box;
        }
      }
    }
    .box_top {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-right: 10px;
      width: 100%;
      .left_item {
        min-width: 64px;
        height: 57px;
        box-sizing: border-box;
        padding: 14px 0;
        margin: 25px;
        cursor: pointer;
        position: relative;

        .l_title {
          font-size: 16px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #1d2129;
        }
        .l_titleen {
          font-size: 16px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #fff;
        }
      }
      .imgbg {
        position: absolute;
        top: 0;
        left: 0;
        width: 240px;
        height: 77px;
        opacity: 0;
        transition: all 0.5s;

        // transform: 0.3;
      }
      .show,
      .left_item:hover {
        border-bottom: 1px solid #2b6bff;
      }
      // .left_item:hover{
      //    background: #0052DA;
      //    div{
      //       color: #fff !important;
      //    }
      // }
      // .itemact1:hover{
      //    background:url('../assets/indeximg/leftimg/p1.png');
      //    background-size: 100% 100%;
      // }
      //  .itemact2:hover{
      //    background:url('../assets/indeximg/leftimg/p2.png');
      //    background-size: 100% 100%;
      // }
      // .itemact3:hover{
      //    background:url('../assets/indeximg/leftimg/p3.png');
      //    background-size: 100% 100%;
      // }
      // .itemact4:hover{
      //    background:url('../assets/indeximg/leftimg/p4.png');
      //    background-size: 100% 100%;
      // }
      //   .itemact1 {
      //     background:url('../assets/indeximg/leftimg/p1.png');
      //    background-size: 100% 100%;
      //    div {
      //       color: #fff !important;
      //    }
      // }
      //   .itemact2 {
      //    background:url('../assets/indeximg/leftimg/p2.png');
      //    background-size: 100% 100%;
      //    div {
      //       color: #fff !important;
      //    }
      // }
      //  .itemact3 {
      //    background:url('../assets/indeximg/leftimg/p3.png');
      //    background-size: 100% 100%;
      //    div {
      //       color: #fff !important;
      //    }
      // }
      //  .itemact4 {
      //   background:url('../assets/indeximg/leftimg/p4.png');
      //    background-size: 100% 100%;
      //    div {
      //       color: #fff !important;
      //    }
      // }
      //  .itemact2 {
      //    background:url('../assets/indeximg/leftimg/p2.png');
      //    background-size: 100% 100%;
      //    div {
      //       color: #fff !important;
      //    }
      // }
      .itemact {
        // background:url('../assets/indeximg/leftimg/p1.png');
        // background-size: 100% 100%;
        // div{
        //    color: #fff !important;
        // }
      }
    }
    .box_right {
      width: 100%;
      background: #f7f8fa;
      box-sizing: border-box;
      padding: 32px 28px;
      display: flex;
      flex-direction: column;

      .right_item {
        display: flex;
        justify-content: space-between;
        border-bottom: 1px solid #eee;
        height: 211px;
        margin-bottom: 15px;
        padding-bottom: 15px;
        .item_img {
          width: 330px;
          height: 210px;

          img {
            width: 330px;
            height: 210px;
          }
        }
        .item_con {
          width: 100%;
          height: 180px;
          display: flex;
          flex-direction: column;
          text-align: left;
          margin-left: 20px;
          align-content: center;
          justify-content: space-between;
          cursor: default;
          position: relative;
          .con1 {
            font-size: 18px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            margin: 10px 0;
            color: #242a37;
          }
          .con1:hover {
            color: #0052da;
          }
          .con2 {
            font-size: 14px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #666666;
          }
          .con3 {
            display: flex;
            margin-top: 30px;
            .con3_tab {
              width: 56px;
              height: 22px;
              border: 1px solid #dadada;
              border-radius: 2px;
              text-align: center;
              margin-right: 5px;
              line-height: 22px;
              font-size: 10px;
              font-family: Microsoft YaHei;
              font-weight: 400;
              color: #666666;
              cursor: default;
            }
          }
          .con4 {
            cursor: default;
            font-size: 12px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #999999;
          }
          .btn {
            width: 112px;
            height: 40px;
            border-radius: 2px;
            border: 1px solid #2b6bff;
            font-size: 16px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #2b6bff;
            line-height: 24px;
            display: flex;
            align-items: center;
            justify-content: center;
            position: absolute;
            right: 30px;
            bottom: 0px;
            cursor: pointer;
          }
        }
      }

      // .item_con:hover {
      //   background: linear-gradient(0deg, #eee 0%, #fff 100%);
      // }
    }
  }
 
}
.mainh5 {
  width: 100%;
  max-width: 750px;
  min-height: 100vh;
  .popuph5 {
    box-sizing: border-box;
    padding: 34px;
    width: 666px;
    height: 307px;
    background: #ffffff;
    border-radius: 35px;
    .img {
      width: 82px;
      height: 58px;
      margin: 0 auto;
    }
    .con {
      font-size: 20px;
      font-family: PingFang SC;
      font-weight: bold;
      color: #333649;
      line-height: 30px;
      margin-bottom: 60px;
      margin-top: 40px;
    }
    .bottom {
      div {
        width: 104px;
        height: 41px;
        background: #3051e8;
        box-shadow: 0px 4px 20px 0px rgba(16, 51, 213, 0.29);
        border-radius: 20px;
        font-size: 18px;
        font-family: PingFang SC;
        font-weight: 500;
        color: #ffffff;
        line-height: 41px;
        margin: 0 auto;
      }
    }
  }
  .bannerh5 {
    max-width: 750px;
    min-width: 640px;
    width: 100%;
    .ban_title {
      width: 210px;
      font-size: 35px;
      font-family: PingFang SC;
      font-weight: bold;
      color: #ffffff;
      line-height: 81px;
      display: flex;
      justify-content: center;
    }
    .inpbox {
      display: flex;
      justify-content: left;
      position: absolute;
      width: 470px;
      height: 53px;
      background: #ffffff;
      border-radius: 27px;
      top: 219px;
      //
      left: 50%;
      margin-left: -235px;

      input {
        border: none;
        outline: none;
        width: 390px;
        padding-left: 40px;
        background: rgba(255, 255, 255, 0);
      }
    }
    .help_box {
      width: 100%;
      min-height: 1983px;
      background: #ffffff;
      box-shadow: 0px 1px 118px 8px rgba(48, 81, 232, 0.1);
      z-index: 1;
      box-sizing: border-box;
      padding: 30px 40px;

      .box_title {
        font-size: 40px;
        font-family: PingFang SC;
        font-weight: 800;
        color: #333649;
        line-height: 81px;
        text-align: center;
        margin-bottom: 34px;
        span {
          color: #4661e9;
        }
      }
      .box_title2 {
        text-align: left;
        font-size: 26px;
        font-family: PingFang SC;
        font-weight: 500;
        color: #333649;
        line-height: 40px;
      }
      .form_box {
        margin-top: 94px;
        .form_item {
          .item_title {
            text-align: left;
            font-size: 20px;
            font-family: PingFang SC;
            font-weight: bold;
            color: #333649;
            line-height: 30px;
          }
          .exm {
            font-size: 14px;
            font-family: PingFang SC;
            font-weight: bold;
            color: #9295a9;
            line-height: 30px;
            text-align: left;
          }
          .inp {
            margin-top: 14px;
            .check_box {
              display: flex;
              flex-direction: column;
              text-align: left;
              font-size: 16px;
              font-family: PingFang SC;
              font-weight: 500;
              color: #333649;
              // line-height: 18px;
              // justify-content:left;
              // align-content: left;
            }
          }
        }
        .sub_btn {
          width: 191px;
          height: 39px;
          line-height: 39px;
          background: #3051e8;
          border-radius: 20px;
          font-size: 18px;
          font-family: PingFang SC;
          font-weight: 400;
          color: #ffffff;
          padding: 0;
        }
      }
      .form_boxh5 {
        margin-top: 94px;

        width: 100%;
        min-width: 640px;
        max-width: 750px;
        margin-left: 0px;
        padding-left: 0px;
        .form_item {
          width: 100%;

          .item_title {
            text-align: left;
            font-size: 26px;
            font-family: PingFang SC;
            font-weight: bold;
            color: #333649;
            line-height: 30px;
          }
          .exm {
            font-size: 20px;
            font-family: PingFang SC;
            font-weight: bold;
            color: #9295a9;
            line-height: 30px;
            text-align: left;
          }
          .inp {
            margin-top: 14px;
            .check_box {
              display: flex;
              flex-direction: column;
              text-align: left;
              font-size: 16px;
              font-family: PingFang SC;
              font-weight: 500;
              color: #333649;
              // line-height: 18px;
              // justify-content:left;
              // align-content: left;
            }
          }
        }
        .sub_btn {
          // width: 191px;
          width: 100%;
          height: 50px;
          line-height: 50px;
          background: #3051e8;
          border-radius: 20px;
          font-size: 26px;
          font-family: PingFang SC;
          font-weight: 400;
          color: #ffffff;
          padding: 0;
        }
      }
    }
  }
}
.h5main {
  min-width: 100%;
  background: #ffffff;
  .htop{
            height:125px;
        }
        .banner{
    width: 100%;    
    font-size: 0;
    display: flex;
        align-items: center;
        justify-content: center;
    .simg{
        width: 1000px;
        height: auto;
    }
  }
  .main_box {
    width: 100%;
    .slideshow {
      height: auto;
      .my-swipe {
        width: auto;
        margin: 20px;
      }
      .item {
        width: 100%;
      }
      .pre {
        left: 56px;
        width: 56px;
        height: 56px;
      }
      .next {
        left: calc(100% - 106px);
        width: 56px;
        height: 56px;
      }
    }
    .box_top {
      .left_item {
        .l_title {
          font-size: 32px;
        }
      }
    }
    .box_right {
      background: #ffffff;
      .right_item {
        flex-direction: column;
        height: auto;
        .item_img {
          width: 100%;
          height: auto;
          img {
            width: 100%;
            height: 100%;
          }
        }
        .item_con {
          height: auto;
          .con1 {
            font-size: 32px;
            margin: 20px 0;
          }
          .con2 {
            font-size: 26px;
            line-height: 42px;
          }
          .con3 {
            margin: 20px 0;
            .con3_tab {
              width: auto;
              padding: 10px;
              font-size: 24px;
            }
          }
          .con4 {
            font-size: 24px;
          }
          .btn {
            position: relative;
            position: relative;
            margin: 40px auto;
            height: 68px;
            width: 50%;
            font-size: 28px;
          }
          .btn:hover {
            background: #0052da;
            color: #ffffff;
          }
        }
      }
    }
    .page {
      margin-top: 30px;
      margin-bottom: 40px;
      padding: 30px;
      background: #ffffff;
      ::v-deep {
        i,
        li {
          font-size: 32px;
          padding: 0 20px;
        }
      }
    }
  }
}
</style>